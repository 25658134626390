<template>
  <div class="about">
    <div v-if="loading">
      <Loader />
      <p>RSSフィードを探しています。</p>
    </div>
    <div v-else>
      <p>{{message}}</p>
      <p>RSSを登録したサイトのURLを入力してください。</p>
      <p><input type="text" v-model="url" /></p>
      <button class="submit__btn" aria-label="add rss feed" :disabled="urlchecked" @click="addFeed">追加</button>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Atoms/Loader.vue'
import { mapActions } from 'vuex';
import { uuid } from 'vue-idb'
import dayjs from 'dayjs'
import validUrl from 'valid-url'

export default {
  name: 'AddFeed',
  data() {
    return {
      url: '',
      loading: false,
      message: '',
    }
  },
  components: {
    Loader,
  },
  created(){
    if(this.$route.params.url){
      const parseUrl = new URL(this.$route.params.url)
      this.url = parseUrl.origin + '/'
    }
  },
  computed: {
    urlchecked: function () {
      if(validUrl.isHttpUri(this.url) || validUrl.isHttpsUri(this.url)){
        if(this.validURL(this.url)){
          return false
        }else{
          return true
        }
      }else{
        return true
      }
    }
  },
  methods: {
    ...mapActions([
      'collectionAdd', 'feedAdd'
    ]),
    addFeed: async function(){
      this.loading = true
      this.message = ''

      // feedにあるか
      const feed = await this.$db.feed.where({url: this.url})
            .toArray()
            .then((data)=>{ return data})
      // console.log(exist.length);

      // collectionに登録
      if(feed.length > 0){
        const params = 
        {
          id: uuid(),
          title: feed[0].title,
          feed_id: feed[0].id,
          url: feed[0].url,
          icon: feed[0].icon,
          rss: feed[0].rss,
          updated_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
        }

        await this.collectionAdd(params).then(()=>{
          this.$router.push({ name: 'Home'})
        })
      }else{
        const apiurl = 'https://api.feedclip.net/feed/detail?url=' + this.url

        const feed = await fetch(apiurl).then(response => {return response.json()})
        if(!feed.error){
          // まずはfeedに追加
          await this.feedAdd(feed)

          // collectionに追加
          const params = 
            {
              id: uuid(),
              title: feed.title,
              feed_id: feed.id,
              url: feed.url,
              icon: feed.icon,
              rss: feed.rss,
              updated_at: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
            }
          await this.collectionAdd(params).then(()=>{
            this.$router.push({ name: 'Home'})
          })
        }else{
          this.message = '指定のサイトでRSSフィードが見つかりませんでした。'
          this.url = ''
        }
      }
      this.loading = false
    },
    validURL(str) {
      const pattern = new RegExp('^((https?:)?\\/\\/)?'+ // protocol
          '(?:\\S+(?::\\S*)?@)?' + // authentication
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locater
      if (!pattern.test(str)) {
          return false;
      } else {
          return true;
      }
    }
  }
}

</script>